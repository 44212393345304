<template>
  <Banner :data="dataSlide" />
  <Socila />
  <div class="content-courses">
    <div class="our-crs" data-aos="fade-up">
      <div class="list-crs" v-if="data.courses && data.courses.length > 0">
        <router-link
          v-for="(item, index) in data.courses"
          :key="index"
          class="sm-w-full"
          :to="item.link"
        >
          <div class="item">
            <div class="item__bg">
              <img :src="item.image" alt="" />
            </div>
            <div class="item__content" v-html="item[locale]?.name">
            </div>
            <div class="item__line"></div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="detail-crs" data-aos="fade-up">
      <div class="detail-crs__main">
        <div class="item" v-if="data?.home?.course">
          <div class="item__bg">
            <img :src="data.home.course?.image" alt="" />
          </div>
          <div class="item__content">
            <div class="title" v-html="capitalize(data.home.course[locale])"></div>
            <div class="line"></div>
            <router-link
              class="info-more"
              :to="data.home.course?.link"
              @click="setMenuActive(0, true)"
            >
              {{ trans('frontend.button.more information') }}
            </router-link>
          </div>
        </div>
        <div class="item" v-if="data?.home?.restaurant">
          <div class="item__bg">
            <img :src="data.home.restaurant?.image" alt="" />
          </div>
          <div class="item__content">
            <div class="title" v-html="capitalize(data.home.restaurant[locale])">
            </div>
            <div class="line"></div>
            <router-link
              class="info-more"
              :to="data.home.restaurant?.link"
              @click="setMenuActive(1, 0)"
            >
              {{ trans('frontend.button.more information') }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="detail-crs__sub flex justify-between">
        <div class="item" v-if="data?.home?.villa">
          <div class="item__bg">
            <img :src="data.home.villa?.image" alt="" />
          </div>
          <div class="item__content">
            <div class="title" v-html="capitalize(data.home.villa[locale])"></div>
            <div class="line"></div>
            <div class="info-more">
              <router-link
                class="info-more"
                :to="data.home.villa?.link"
                @click="setMenuActive(2, 0)"
              >
                {{ trans('frontend.button.more information') }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="item" v-if="data?.home?.news">
          <div class="item__bg">
            <img :src="data.home.news?.image" alt="" />
          </div>
          <div class="item__content">
            <div class="title" v-html="capitalize(data.home.news[locale])"></div>
            <div class="line"></div>
            <router-link
              class="info-more"
              :to="data.home.news?.link"
              @click="setMenuActive(3, 0)"
            >
              {{ trans('frontend.button.more information') }}
            </router-link>
          </div>
        </div>
        <div class="item" v-if="data?.home?.member">
          <div class="item__bg">
            <img :src="data.home.member?.image" alt="" />
          </div>
          <div class="item__content">
            <div class="title" v-html="capitalize(data.home.member[locale])"></div>
            <div class="line"></div>
            <div class="info-more">
              <router-link
                class="info-more"
                :to="data.home.member?.link"
                @click="setMenuActive(4, true)"
              >
                {{ trans('frontend.button.more information') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <popupPromo :data="dataPopUp" v-if="this.info.popup == 1 && this.dataPopUp"/>
  </div>
</template>

<script>
/* global $ */
// import { mapMutations } from "vuex";
import Banner from "@/components/home/Banner.vue";
import Socila from "@/components/home/Socila.vue";
import popupPromo from "@/components/popup/popupPromo.vue";
import HomeSerive from "@/services/HomeSerive";
import { mapGetters } from "vuex";
export default {
  components: {
    Banner,
    popupPromo,
    Socila,
  },
  data() {
    return {
      data: "",
      dataPopUp: "",
      dataSlide: "",
      itemMain: [],
      itemSub: [],
    };
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.dataHome();
    this.dataPop();
  },
  computed: {
    ...mapGetters(["locale", "info"]),
  },
  methods: {
    // ...mapMutations(["setMenuActive"]),
    activePopup() {
      $("#popupcommonpromos").removeClass("hide");
      $("#popupcommonpromos").addClass("active");
    },
    setMenuActive(index, sub) {
      this.$store.commit("setMenuActive", index);
      this.$store.commit("setSubActive", sub);
    },
    async dataHome() {
      await HomeSerive.getHome()
        .then((resp) => {
          this.data = resp.data.data;
          this.dataSlide = this.data.slides;
        })
        .catch(() => {});
    },
    async dataPop() {
      await HomeSerive.getPopUp()
        .then((resp) => {
          this.dataPopUp = resp.data.data;
          if (this.info.popup == 1 && this.dataPopUp) {
            setTimeout(() => {
              this.activePopup();
            }, 1500);
          }
        })
        .catch(() => {});
    },
  },
};
</script>
