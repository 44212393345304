<template>
  <popupcommon :iconClose="iconClose" :opacity="true" :attr="'promos'" v-if="data">
    <div class="popup-main__promos" v-if="data">
      <div class="popup__slide">
        <swiper
          :modules="modules"
          :effect="'fade'"
          :pagination="{
            clickable: true,
          }"
          :autoplay="{
            delay: 5000,
            disableOnInteraction: false,
          }"
          :loop="true"
          :slides-per-view="1"
          v-if="data.length > 0"
        >
          <swiper-slide v-for="(item, index) in data" :key="index">
            <div class="popup__bg">
              <img :src="item?.image" alt="" />
            </div>
            <div class="popup__detail">
              <div class="title">
                <h1>
                  {{ item[locale]?.title }}
                </h1>
                <p class="des" v-html="item[locale]?.content">
                </p>
              </div>
              <div class="view-more">
                <a :href="item.path">CHI TIẾT</a>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="popup-main__close" @click="closePop()">
        <img v-if="iconClose" src="../../assets/img/icons/close.svg" alt="" />
        <img v-else src="../../assets/img/icons/close-black.svg" alt="" />
      </div>
    </div>
  </popupcommon>
</template>
<script>
/* global $ */
import { Pagination, EffectFade, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import popupcommon from "@/components/popup/popupcommon.vue";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import { mapGetters } from 'vuex';
export default {
  components: { Swiper, SwiperSlide, popupcommon },
  props: {
    data: null,
  },
  data() {
    return {
      iconClose: true,
      dataImg: [
        "./img/common/img-promo.png",
        "./img/album/popup-banner.png",
        "./img/common/img-promo.png",
        "./img/album/popup-banner.png",
        "./img/common/img-promo.png",
        "./img/album/popup-banner.png",
      ],
    };
  },
  setup() {
    // const onSwiper = (swiper) => {
    //   console.log(swiper);
    // };
    // const onSlideChange = () => {
    //   console.log("slide change");
    // };
    return {
      // onSwiper,
      // onSlideChange,
      modules: [Pagination, EffectFade, Autoplay],
    };
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  methods: {
    closePop() {
      $(".popup-common").removeClass("active");
      $(".popup-common").addClass("hide");
    },
  },
};
</script>
