import http from "@/http/http-common";
const resource = "/api/frontend";
class HomeSerive {
    getHome() {
        return http.get(`${resource}/home`);
    }
    getPopUp() {
        return http.get(`${resource}/popup`);
    }
}

export default new HomeSerive();