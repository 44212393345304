<template>
  <div class="home-banner" v-if="data">
    <swiper
      :modules="modules"
      :slides-per-view="1"
      :effect="'fade'"
      :autoplay="{
        delay: 5000,
        disableOnInteraction: false,
      }"
      navigation
      :loop="true"
      :pagination="{ clickable: true }"
    >
      <swiper-slide v-for="(item, index) in data" :key="index">
        <img :src="item.image" alt="banner" />
      </swiper-slide>
    </swiper>
    <div class="content-banner">
      <div class="logo-b">
        <img src="../../assets/img/logo/amber.svg" alt="" />
      </div>
      <div class="book" @click="activeMenuP()">
        <router-link :to="'/contact'">ĐẶT CHỖ</router-link>
      </div>
    </div>
    <div class="our-courses">
      <div class="content">SÂN CỦA CHÚNG TÔI</div>
    </div>
  </div>
</template>
<script>
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
// import "swiper/modules/scrollbar/scrollbar.min.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    data: null
  },
  setup() {
    // const onSwiper = (swiper) => {
    //   console.log(swiper);
    // };
    // const onSlideChange = () => {
    //   console.log("slide change");
    // };
    return {
      // onSwiper,
      // onSlideChange,
      modules: [Navigation, Pagination, Autoplay, EffectFade],
    };
  },
  data() {
    return {
      swiperOption: {
        autoplay: {
          delay: 5000,
        },
        loop: true,
        effect: "fade",
      },
    };
  },
  methods: {
    activeMenuP() {
      this.$store.commit("setMenuActive", 6);
      this.$store.commit("setSubActive", 0);
    },
  }
};
</script>
