<template>
  <div class="social-left">
    <div class="list-social">
      <div class="social__phone item">
        <img src="../../assets/img/promo/phone-p.svg" alt="" />
        <span>{{ info.hotline_1 }}</span>
      </div>
      <a :href="info.facebook_link" target="_blank" rel="noopener noreferrer">
        <div class="social__fb item">
          <img src="../../assets/img/promo/fb-f.svg" alt="" />
          <span>Facebook </span>
        </div>
      </a>
    </div>
  </div>
  <!-- <div class="social-bottom">
    <div class="social__messenger item">
      <img src="../../assets/img/promo/messenger.svg" alt="" />
    </div>
  </div> -->
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["info"]),
  },
  methods: {},
};
</script>
